<template>
  <div>
    <DefaultRegister
      :title="$t('register.poaVerification.proofOfAddressVer')"
      :subTitle="$t(`register.poaVerification.subTitle`)"
      :narrative="$t(`register.poaVerification.narrative`)"
    >
      <template v-slot:header>
        <p>{{ $t(`register.poaVerification.dueToAML`) }}</p>
      </template>
      <template v-slot:content>
        <router-view />
      </template>
    </DefaultRegister>
  </div>
</template>

<script>
import DefaultRegister from '@/components/template/register/Default';

export default {
  name: 'poaVerificationIndex',
  components: { DefaultRegister }
};
</script>
